import React from "react"

import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"
import { overlay } from "../styles/util"
import { css } from "@emotion/core"

const NotFoundPage = ({ data }) => (
  <Layout>
    <SEO title="404: Not found" />
    <section>
      <Img css={overlay} alt="Photo of Hunter looking confused and dismayed" fluid={data.file.childImageSharp.fluid} />
    </section>
    <section css={styles.section}>
      <p>You just hit a route that doesn&#39;t exist...</p>
      <Link to="/" css={styles.a}><i class="far fa-frown-open"></i></Link>
    </section>
  </Layout>
)

const styles = {
  section: css`
    font-family: 'Open Sans', sans-serif;
    padding: 16px;
  `,
  a: css`
    font-size: 3rem;
    display: block;
    text-align: center;
  `
}

export default NotFoundPage

export const query = graphql`
  query {
    file(relativePath: { eq: "404.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 960) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
